import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import mq from '../utils/mq';
import NodeJsIcon from '../images/svg/node-js.svg';
import GolangIcon from '../images/svg/go.svg';
import ReactJsIcon from '../images/svg/reactjsicon.svg';
import ReduxIcon from '../images/svg/redux.svg';
import JavaIcon from '../images/svg/java.svg';
import JavascriptIcon from '../images/svg/javascript.svg';
import SpringIcon from '../images/svg/spring.svg';
import DockerIcon from '../images/svg/docker.svg';
import GitlabIcon from '../images/svg/gitlab.svg';
import JiraIcon from '../images/svg/jira.svg';
import GitIcon from '../images/svg/git.svg';
import RestIcon from '../images/svg/rest.svg';

const stack = [
  { name: 'Golang', color: '#67d1df', icon: GolangIcon },
  { name: 'REST APIs', color: '#87be40', icon: RestIcon },
  { name: 'Javascript', color: '#f7df1e', icon: JavascriptIcon },
  { name: 'React', color: '#53c1de', icon: ReactJsIcon },
  { name: 'Redux', color: '#764abc', icon: ReduxIcon },
  { name: 'Node JS', color: '#2c9a52', icon: NodeJsIcon },
  { name: 'Git', color: '#f05033', icon: GitIcon },
  { name: 'Docker', color: '#2496ed', icon: DockerIcon },
  { name: 'Java', color: '#f8981d', icon: JavaIcon },
  { name: 'Spring', color: '#68bd45', icon: SpringIcon },
  { name: 'Jira', color: '#0071ff', icon: JiraIcon },
  { name: 'Gitlab', color: '#dc4128', icon: GitlabIcon },
];

export const newAnimation = () => ({
  started: false,
  running: false,
  currentIndex: stack.length - 1,
  text: '',
  textFilling: false,
});

export const playAnimation = anim => {
  return { ...anim, started: true, running: true };
};

export const wakeUpAnimation = anim => {
  return { ...anim, textFilling: !anim.textFilling, running: true };
};

export const stepAnimation = (anim, wakeFn) => {
  var newAnim = { ...anim };

  //if it is not running, get out
  if (!newAnim.running) {
    return newAnim;
  }

  const tech = stack[newAnim.currentIndex];

  // typing the word
  if (newAnim.textFilling && newAnim.text.length < tech.name.length) {
    newAnim.text = tech.name.substring(0, newAnim.text.length + 1);
    return newAnim;
  }

  // finished typing
  if (newAnim.textFilling && newAnim.text.length === tech.name.length) {
    newAnim.running = false;
    setTimeout(() => {
      wakeFn();
    }, 3000);

    return newAnim;
  }

  // erasing the word
  if (!newAnim.textFilling && newAnim.text.length > 0) {
    newAnim.text = newAnim.text.substring(0, newAnim.text.length - 1);
    return newAnim;
  }

  // finished erasing the word, go to the next one
  if (!newAnim.textFilling && newAnim.text.length === 0) {
    newAnim.currentIndex++;
    if (newAnim.currentIndex === stack.length) {
      newAnim.currentIndex = 0;
    }

    newAnim.textFilling = true;
    return newAnim;
  }

  // should not reach this point, ever!
  return newAnim;
};

export class TextAnimation extends React.Component {
  render() {
    const { currentIndex, text } = this.props.animation;
    const { color } = stack[currentIndex];

    return <span css={css({ color })}>{text}</span>;
  }
}

TextAnimation.propTypes = {
  animation: PropTypes.shape({
    currentIndex: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

const Background = styled.div`
  padding-top: 2rem;
  padding-bottom: 0;
`;

const popAnimation = keyframes`
  0% {
    left: 50%;
    transform: translateX(-50%) scale(0.1)
    opacity: 0;
  }
  50% {
    left: 50%;
    transform: translateX(-50%) scale(1);
    opacity: 1
  }
  60% {
    left: 50%;
    transform: translateX(-50%) scale(1) rotate(10deg);
    opacity: 1;
  }
  70% {
    left: 50%;
    transform: translateX(-50%) scale(1.2) rotate(-10deg);
    opacity: 1;
  }
  80% {
    left: 50%;
    transform: translateX(-50%) scale(1) rotate(10deg);
    opacity: 1;
  }
  90% {
    left: 50%;
    transform: translateX(-50%) scale(1.2) rotate(-10deg);
    opacity: 1;
  }
  100% {
    left: 50%;
    transform: translateX(-50%) scale(1);
    opacity: 1
  }
`;

const slideAnimation = keyframes`
      0% {
        left: 100%;
        transform: translateX(-50%);
        opacity: 0;
      }
      100% {
        left: 50%;
        transform: translateX(-50%);
        opacity: 1;
      }
    `;

const iconCss = css`
  width: 11rem;
  height: 11rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%) scale(0.1);
  animation: ${popAnimation} 1s ease forwards;

  ${mq.bph1} {
    width: 13rem;
    height: 13rem;
  }

  ${mq.bpwh1} {
    width: 13.5rem;
    height: 13.5rem;
  }

  ${mq.bpw4} {
    transform: translateX(-50%) scale(0.1);
    animation: ${slideAnimation} 1s ease forwards;
  }
`;

export class IconAnimation extends React.Component {
  render() {
    const { currentIndex } = this.props.animation;
    const Icon = stack[currentIndex].icon;

    return (
      <Background>
        <Icon css={iconCss} />
      </Background>
    );
  }
}

IconAnimation.propTypes = {
  animation: PropTypes.shape({
    currentIndex: PropTypes.number.isRequired,
  }).isRequired,
};
