import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import theme from '../utils/theme';
import mq from '../utils/mq';
import {
  IconAnimation,
  TextAnimation,
  newAnimation,
  playAnimation,
  stepAnimation,
  wakeUpAnimation,
} from './landing-animation';
import LatestPosts from './landing-latest';
import TagCloud from './tagcloud';

const Container = styled.div`
  position: relative;
`;

const Promo = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
`;

const Question = styled.h1`
  font-size: 2.5rem;
  color: ${theme.dark.fgColor};
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  ${mq.bph1} {
    font-size: 3.5rem;
  }
`;

const Answer = styled.h2`
  font-size: 1.15rem;
  color: ${theme.dark.fgColor};
  margin-top: 0.5rem;
  margin-bottom: 0;

  ${mq.bpw1} {
    font-size: 1.3rem;
  }

  ${mq.bpwh1} {
    font-size: 1.55rem;
  }

  ${mq.bph2} {
    margin-top: 1.5rem;
  }
`;

const MoreInfo = styled.div`
  display: block;
  color: ${theme.dark.fgColor};
  text-align: center;
  margin-top: 0.3rem;
  font-size: 0.85rem;
  & a {
    color: ${theme.dark.fgColor};
    background-image: none;
    text-shadow: none;
    text-decoration: underline;
  }

  ${mq.bpwh1} {
    font-size: 1rem;
    margin-top: 1.5rem;
  }

  ${mq.bph2} {
    display: none;
  }
`;

const Marker = styled.span`
  color: transparent;
  line-height: 52px;
  animation: 1s blink step-end infinite;

  @keyframes "blink" {
    from,
    to {
      color: transparent;
    }
    50% {
      color: ${theme.dark.fgColor};
    }
  }
`;

class LandingBio extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.state = { anim: newAnimation() };

    this.startTimer = this.startTimer.bind(this);
    this.updateTech = this.updateTech.bind(this);
    this.awaken = this.awaken.bind(this);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  startTimer() {
    if (this.state.anim.started) {
      return;
    }

    this.setState({ anim: playAnimation(this.state.anim) });
    this.timer = setInterval(() => {
      this.updateTech();
    }, 50);
  }

  awaken() {
    if (!this.timer) {
      return;
    }

    this.setState({ anim: wakeUpAnimation(this.state.anim) });
  }

  updateTech() {
    if (!this.timer) {
      return;
    }

    const anim = stepAnimation(this.state.anim, this.awaken);
    this.setState({ anim });
  }

  render() {
    const { running } = this.state.anim;
    const dot = running ? '' : '.';

    return (
      <StaticQuery
        query={graphql`
          query Bio {
            allMarkdownRemark(
              sort: { fields: frontmatter___date, order: DESC }
              limit: 5
            ) {
              edges {
                node {
                  id
                  excerpt(pruneLength: 250)
                  frontmatter {
                    date(formatString: "DD MMM, YYYY")
                    path
                    title
                  }
                }
              }
            }

            allTags: allMarkdownRemark {
              group(field: frontmatter___tags) {
                tag: fieldValue
                count: totalCount
              }
            }
          }
        `}
        render={data => (
          <Container>
            <Promo>
              <Question>
                Hi, I'm a software
                <br />
                developer.
              </Question>
              <IconAnimation animation={this.state.anim} />
              <Answer>
                I can help you with{' '}
                <TextAnimation animation={this.state.anim} />
                {dot}
                <Marker>▌</Marker>
              </Answer>
              <MoreInfo>
                Know more about{' '}
                <Link to="/about" title="About">
                  me
                </Link>{' '}
                or{' '}
                <a title="Github projects" href="https://github.com/ibraimgm">
                  my projects
                </a>
                .
              </MoreInfo>
            </Promo>
            <LatestPosts data={data.allMarkdownRemark.edges} />
            <TagCloud
              data={data.allTags.group}
              customCss={css`
                display: none;

                ${mq.bph3} {
                  display: block;
                }
              `}
            />
          </Container>
        )}
      />
    );
  }
}

export default LandingBio;
