import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import theme from '../utils/theme';
import mq from '../utils/mq';

const latestCss = css`
  display: none;
  position: relative;
  width: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
  color: ${theme.dark.fgColor};

  ${mq.bph2} {
    display: block;
  }

  & h3 {
    color: ${theme.dark.fgColor};
    margin-top: 0;
  }

  & ul {
    list-style: none;
  }

  & li {
    margin-bottom: 0.3rem;
    &::before {
      content: '▪';
      color: ${theme.dark.fgColor};
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    & time {
      font-size: 0.75rem;
      color: ${theme.term.green};
      background-color: #000000;
      padding: 0.3rem;
      border-radius: 3px;
    }

    & a {
      color: ${theme.dark.fgColor};
      text-shadow: none;
      background-image: none;
      margin-left: 0.5rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Post = ({ data }) => {
  return (
    <li>
      <time dateTime={data.frontmatter.date}>{data.frontmatter.date}</time>
      <Link title={data.frontmatter.title} to={data.frontmatter.path}>
        {data.frontmatter.title}
      </Link>
    </li>
  );
};

const LatestPosts = props => {
  const posts = props.data.map((node, index) => (
    <Post key={index} data={node.node} />
  ));

  return (
    <div css={latestCss}>
      <h3>Latest posts</h3>
      <ul>{posts}</ul>
    </div>
  );
};

export default LatestPosts;
