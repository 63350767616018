import React from 'react';

import LandingBio from '../components/landing-bio';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout
    scheme="dark"
    main={
      <React.Fragment>
        <SEO
          title="Home"
          keywords={[`developer`, 'development', `blog`, `programming`, `oss`]}
        />
        <LandingBio />
      </React.Fragment>
    }
  ></Layout>
);

export default IndexPage;
